import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
// import AnimatedRoutes from "./AnimatedRoutes"

import "bootstrap/dist/css/bootstrap.min.css";
// Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min";

import Home from './page/Home';
import About from './page/AboutUs';
import Estate from './page/Estate/Estates';
import EstateDetails from './page/Estate/EstateDetails';
import Services from './page/Services';
import Events from './page/Events/Events';
import EventGallery from './page/Events/EventGallery';
import EventGalleryTwo from './page/Events/EventGalleryTwo';
import InterestForm from './page/InterestForm';
import Realtor from './page/Realtor';
import RealtorForm from './page/RealtorForm';
import SuccessPage from './page/SuccessPage'

function App() {
  return (
    <BrowserRouter>
      
      <Routes>

        <Route path="/" element={<Home />} exact/>

        <Route path="/About-Us" element={<About />} exact/>

        <Route path="/Estates-Layout" element={<Estate />}  exact/>
                
        <Route path="/EstateDetails/:name" element={<EstateDetails />}  exact/>
                
        <Route path="/Services" element={<Services />}  exact/>
            
        <Route path="/Events" element={<Events />}  exact/>

        <Route path="/RealtorForm/:username" element={<RealtorForm />}  exact/>
                
        <Route path="/Interest-Form" element={<InterestForm />}  exact/>
                
        <Route path="/Massive-Sales-Submit" element={<EventGallery />}  exact/>
                
        <Route path="/Chop" element={<EventGalleryTwo />}  exact/>

        <Route path="/Realtor-Registration" element={<Realtor />} exact/>

        <Route path="/Success" element={<SuccessPage/>} exact/>


        </Routes>
    </BrowserRouter>
  );
}

export default App;
