import React from 'react';
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

import NavBar from './page/components/NavBar';
import Footer from './page/components/Footer';

// import {motion} from "framer-motion/dist/framer-motion"

const Layout = ({ children }) => {



    return (



        <div className='layout-body'>

            <ToastContainer
                toastStyle={{
                    zIndex: "9999 !important",
                }}
            />

            <NavBar />

            <div className='content'>


                {children}

            </div>

            <Footer />

        </div>



    );
};

export default Layout;