
// import axios from "axios";
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Image, Button } from 'react-bootstrap';
import Layout from '../Layout';

const SuccessPage = () => {
    const location = useLocation();
    const { email } = location.state || {};




    return (
        <>

<Layout>

            <div class="section-1">
                <div class="container">
                    <div class="row">
                        <div class="col"></div>

                        <div class="col-md-6 mb-5 mt-5">
                                <div className=' d-flex justify-content-center align-items-center'>
                                    <Image src={"/img/email2.png"}
                                        alt="profile"
                                        style={{ width: '50%', height: '50%' }} />
                                </div>
                                <div className='text-center'>
                                    <h3>Registration Successful!</h3>
                                    {email && <p style={{ color: "#000" }}>An email has been sent to: <span style={{ color: "#0b9967" }}>{email}</span></p>}
                                </div>
                            </div>

                        <div class="col"></div>

                    </div>

                </div>
            </div>

        </Layout>

        </>
    );
};

export default SuccessPage;